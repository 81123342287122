<template>
  <div>
    <div class="target-header-body">
      <div style="display: flex">
        <a-upload
          v-model:file-list="fileList"
          name="file"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          @change="handleUpload"
        >
          <a-button>
            <UploadOutlined />
            Choose File To Upload
          </a-button>
        </a-upload>
        <a-input-search
          v-model:value="searchValue"
          placeholder="Search"
          auto-size
        />
        &nbsp;
        <a-button class="target-color-border">Upload</a-button>
      </div>
    </div>
    <div class="targets-filter-body">
      <span> Financial Year &nbsp;</span>
      <a-select
        v-model:value="financialYear"
        style="width: 200px"
        :options="financialYearOptions"
        placeholder="Select"
        @change="financialYearChanged"
      >
      </a-select>
    </div>
    <div class="target-table-candidate-listing">
      <a-table
        :row-selection="rowSelection"
        :columns="candidateColumns"
        :data-source="targetData"
        :pagination="false"
        :rowKey="(record) => record.id"
      />
    </div>
    <div v-if="targetData.length >= 10" class="view-more-btn">
      <a-button type="link" @click="fetchMoreTargets">
        <template #icon>
          <PlusCircleOutlined />
          View More
        </template>
      </a-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { UploadOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import Services from "../../../services/apis";
import Utils from "../../../utils";

let targetData = reactive([]);
const fileList = ref([]);
const todaysDate = new Date()
const currentYear = todaysDate.getFullYear()
const financialYear = ref(currentYear);
const financialYearOptions = ref([
  {
    value: 2018,
    label: "2018",
  },
  {
    value: 2019,
    label: "2019",
  },
  {
    value: 2020,
    label: "2020",
  },
  {
    value: 2021,
    label: "2021",
  },
  {
    value: 2022,
    label: "2022",
  },
]);
const searchValue = ref("");
let pageCount = 0;

export default defineComponent({
  components: {
    UploadOutlined,
    PlusCircleOutlined
  },

  setup() {
    const fetchTargets = async () => {
      let listData = [];
      const year = financialYear.value;
      const type = "TEAM_REVENUE";

      if (pageCount > 0) {
        listData = targetData;
      } else {
        targetData.splice(0);
      }

      const payload = {
        pageNo: pageCount,
        type,
        year,
      };

      await Services.fetchTargets(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data[0].count &&
            data[0].targets.map((target) => {
              listData.push({
                id: target.id,
                subTeam: target.subTeam.name,
                team: target.team.name,
                startDate: Utils.displayShortDate(target.startDate),
                targets: target.target,
              });
            });
          if (data[0].count === 0) {
            targetData.splice(0);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Object.assign(targetData, listData);
    };
    onMounted(() => {
      targetData.splice(0);
      fetchTargets();
    });

    const fetchMoreTargets = () => {
      pageCount++;
      fetchTargets();
    }

    const candidateColumns = [
      {
        title: "Team",
        dataIndex: "team",
      },
      {
        title: "Sub Team",
        dataIndex: "subTeam",
      },

      {
        title: "Start Date",
        dataIndex: "startDate",
      },
      {
        title: "Targets",
        dataIndex: "targets",
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };

    const financialYearChanged = (year) => {
      targetData.slice(0);
      fetchTargets();
    };

    const handleUpload = (info) => {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

    return {
      fetchMoreTargets,
      financialYearChanged,
      fileList,
      searchValue,
      financialYear,
      financialYearOptions,
      handleUpload,
      candidateColumns,
      targetData,
      rowSelection,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/targets.scss";
</style>
