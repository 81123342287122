<template>
  <div class="right-action-section">
    <div>
      <div class="switch-div">
        <center>
          <div class="right-action-body">
            <div class="right-action-body-main">
            <div class="right-action-div-off">
              <a-button type="link"></a-button>
            </div>
            <div class="planner-div active-block">
              <a-button type="link"
                ><span style="margin-top: 0.4rem; color: #4f4cff">
                  Planner</span
                ></a-button
              >
            </div>
          </div>
          </div>
        </center>
      </div>
      <a-calendar
        class="target-custom-calender"
        
        v-model:value="value"
        :fullscreen="false"
        @panelChange="onPanelChange"
      />
    </div>
    <div class="checkbox-div">
     
      <div class="checkbox-div">
        <a-button
          class="target-cal-btn"
          type="link"
          block
          ><strong>Tasks</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showProceduresDashboard"
          class="target-cal-btn"
          type="link"
          block
          ><strong>Targets</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showRevenueDashboard"
          class="target-cal-btn"
          type="link"
          block
          ><strong>Leads</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showInvoicesDashboard"
          class="target-cal-btn"
          type="link"
          block
          ><strong>Notes</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
        <a-button
          @click="showClientBirthdaysDashboard"
          class="target-cal-btn"
          type="link"
          block
          ><strong>Client Birthdays</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>

     <div>
        <!-- <a-modal
          class="filter-modal"
          title=""
          v-model:visible="showPMS"
          :closable="false"
          centered
          @ok="vacancyFilterModalOk"
          width="1200px"
        >
          <div>
            <div style="width=80%">
           <PMSPopup v-if="displayPMSPopup" />
            </div>
          </div>
          <template #footer>
            <a-button
              type="link"
              style="
                color: rgb(76, 81, 85);
                font-size: $font_size_12;
                font-family: Poppins;
                font-weight: 500;
              "
              key="back"
              >Cancel</a-button
            >
            <a-button
              key="submit"
              style="
                background: RGB(228, 129, 106);
                color: #ffffff;
                border-radius: 15px;
                font-size: $font_size_12;
                font-family: Poppins;
                font-weight: 500;
              "
              >Apply Filter</a-button
            >
          </template>
        </a-modal> -->
        <a-button
          @click="showPMSPopup"
          class="target-cal-btn"
          type="link"
          block
          ><strong>PMS Backup Sheet</strong>

          <!-- <span style="float: left">(20)</span> -->
        </a-button>
      </div>
      </div>
    </div>
    <!-- <RightOutlined style="display: none" /> -->
  </div>
</template>
        
<script>
import { defineComponent, ref, reactive, toRefs } from "vue";
import {
  
  // RightOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    
  },
  data() {
    return {
      unhide: true,
    };
  },
  setup(props, context) {
    const value = ref();
    const calenderCheckbox = ref();
    const checked = ref(1);
    const showMeetings = ref(false);
    const showProcedures = ref(false);
    const showRevenues = ref(false);
    const showInvoices = ref(false);
    const showPMS = ref(false);
    const showClientBirthdays = ref(false);

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const state = reactive({
      checked1: true,
    });

    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    function showMeetingsDashboard() {
      context.emit("showMeetingsDashboard", showMeetings, true);
    }

    function showProceduresDashboard() {
      context.emit("showProceduresDashboard", showProcedures, true);
    }

    function showRevenueDashboard() {
      context.emit("showRevenueDashboard", showRevenues, true);
    }

    function showInvoicesDashboard() {
      context.emit("showInvoicesDashboard", showInvoices, true);
    }

    function showClientBirthdaysDashboard() {
      context.emit("showClientBirthdaysDashboard", showClientBirthdays, true);
    }

    function showPMSPopup() {
      context.emit("showPMSPopup", showPMS, true);
    }

    return {
      activeKey: ref("1"),
      value,
      calenderCheckbox,
      checked,
      onPanelChange,
      rowSelection,
      ...toRefs(state),
      showMeetings,
      showMeetingsDashboard,
      showProcedures,
      showProceduresDashboard,
      showRevenues,
      showRevenueDashboard,
      showInvoices,
      showInvoicesDashboard,
      showClientBirthdays,
      showClientBirthdaysDashboard,
      showPMS,
      showPMSPopup,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/RightSideBar.scss";
</style>
