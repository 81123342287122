<template>
  <div>
    <div class="target-main-body" style="display: flex">
      <div class="left-target-body" :style="[rightSideBarVisibility ? ' width : 73%' : ' width : 100%']">
        <a-tabs class="targets-tab-body" v-model:activeKey="activeKey" :animated="false" >
          <a-tab-pane key="Client Meetings" tab="Client Meetings">
            <listClientMeetings />
          </a-tab-pane>
          <a-tab-pane key="Candidate Meetings" tab="Candidate Meetings"
            ><listCandidate
          /></a-tab-pane>
          <a-tab-pane key="CIC Revenue" tab="CIC Revenue"
            ><listCIC
          /></a-tab-pane>
          <a-tab-pane key="Team Revenue" tab="Team Revenue"
            ><listTeam
          /></a-tab-pane>
          <a-tab-pane key="Client Originated" tab="Client Originated"
            ><clientOriginated
          /></a-tab-pane>
          <template #tabBarExtraContent>
            <div class="targets-btn-body">
              <a-button class="target-color-border">Download</a-button>
              <a-button class="target-color-btn"
                >Actions <DownOutlined
              /></a-button>
            </div>
          </template>
        </a-tabs>
      </div>
      <div>
        <LeftCircleTwoTone
          v-if="!rightSideBarVisibility"
          @click="showRightSideBar"
          style="color: blue; font-size: 1.5rem"
        />
        <RightCircleTwoTone
          v-if="rightSideBarVisibility"
          @click="showRightSideBar"
          style="
            font-size: 1.5rem;
            position: fixed;
            z-index: 9;
            transform: translateX(-10px);
          "
        />
      </div>
      <div v-if="rightSideBarVisibility" class="action-bar" style="flex: 1">
        <RightSideBar />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { DownOutlined,RightCircleTwoTone,LeftCircleTwoTone } from "@ant-design/icons-vue";
import listClientMeetings from "../components/listClientMeetings.vue";
import listCandidate from "../components/listCandidateMeetings.vue";
import listCIC from "../components/listCandidateMeetings.vue";
import listTeam from "../components/teamList.vue";
import clientOriginated from "../components/clientOriginated.vue";
import RightSideBar from "../components/RightSideBar.vue";
let rightSideBarVisibility = ref(false);
const showRightSideBar = () => {
  rightSideBarVisibility.value = !rightSideBarVisibility.value;
};
export default defineComponent({
  components: {
    DownOutlined, 
    listClientMeetings,
    listCandidate,
    listCIC,
    listTeam,
    clientOriginated,
    RightSideBar,
    LeftCircleTwoTone,
    RightCircleTwoTone,
  },
  setup() {
    onMounted(() => {});
    return {
      activeKey: ref("Client Meetings"),
      rightSideBarVisibility,
      showRightSideBar,
    };
  },
});
</script>
<style lang="scss">
@import "../styles/targets.scss";
</style>
